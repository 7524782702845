import { _ } from "@/pages/_app";
import { useEffect, useState } from "react";
import Button from "../buttons/Button";
import { setNotTrack } from "@/utils/analyticsFunctions";
import { set } from "date-fns";
import SecondaryButton from "../buttons/SecondaryButton";

const CookieBanner = () => {
  const [hide, setHide] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      // setHide(false);
      setHide(!!window.localStorage.getItem("allowed-cookies"));
    }, 3000);
  }, []);

  if (hide) {
    return null;
  }

  return (
    <div
      id="cookie-banner"
      className="bg-white  border border-neutral-gray sticky bottom-0 shadow-md rounded-md p-2 text-xs font-light right-2 text-center"
      style={{ minWidth: 280 }}
    >
      {/* <div className="text-heading-6">{_("cookie-banner__title")}</div> */}
      <div className="">{_("cookie-banner__info")}</div>
      <a
        className="underline block"
        href="https://www.horseday.com/terms-privacy"
      >
        {_("cookie-banner__terms-of-use")}
      </a>
      <div className="mt-4 flex flex-col sm:flex-row justify-center items-center gap-2 sm:gap-4">
        <SecondaryButton
          className="text-center w-32 mb-1"
          onClick={() => {
            setHide(true);
            setNotTrack();
            window.localStorage.setItem("allowed-cookies", "false");
          }}
        >
          {_("cookie-banner__decline-btn")}
        </SecondaryButton>
        <Button
          className="sm:mr-2 text-center w-32 mb-1"
          onClick={() => {
            setHide(true);
            window.localStorage.setItem("allowed-cookies", "true");
          }}
        >
          {_("cookie-banner__ok-btn")}
        </Button>
      </div>
    </div>
  );
};

export default CookieBanner;
