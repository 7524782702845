import { useAuth } from "@/auth";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import CheckoutNavbar from "./checkoutNavbar";
import CookieBanner from "./misc/CookieBanner";

interface CheckoutLayoutProps {
  children: JSX.Element;
  className?: string;
}

export const getLatestRouteBeforeLogin = () => {
  return latestRouteBeforeLogin;
};

let latestRouteBeforeLogin = "/";

const CheckoutLayout = (props: CheckoutLayoutProps) => {
  const { auth, loading, isAuthenticated } = useAuth();
  const [showNotLoggedIn, setShowNotLoggedIn] = useState(false);
  const router = useRouter();
  const [seed, setSeed] = useState(1);

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      setShowNotLoggedIn(true);
    }
  }, [loading, isAuthenticated]);

  return (
    <div key={seed} style={{ minHeight: "100vh" }}>
      <CheckoutNavbar
        onLangChange={() => {
          // props.children.key = 1;
          window.location.reload();
          setSeed(Math.random());
        }}
      />
      <div
        className={"m-auto bg-eggshell " + (props.className ?? "")}
        style={{ minHeight: window.innerHeight - 72 }}
      >
        <main
          className={"flex-grow col-span-2 "}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: 48,
          }}
        >
          {props.children}
        </main>
        <CookieBanner />
      </div>
    </div>
  );
};

export default CheckoutLayout;
