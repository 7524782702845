import Spinner from "../misc/Spinner";
import { clsx } from "clsx";
import { ButtonProps } from "./Button";

const SecondaryButton = (props: ButtonProps) => {
  return (
    <button
      onClick={() => {
        if (props.loading) {
          return;
        }
        props.onClick();
      }}
      style={{ maxHeight: 64}}
      className={clsx(
        "border border-primary-brown bg-white hover:shadow-sm w-full shadow-md font-medium py-2 px-4 rounded-lg text-sm pt-3 pb-3 ",
        props.className && props.className!.includes("bg-")
          ? ""
          : "hover:bg-lightest-gray",
        props.className
      )}
      type="button"
    >
      {props.loading ? (
        <Spinner className="flex flex-row justify-center" size={5} />
      ) : (
        <>
          {props.icon != null && (
            <div className="inline mr-8 ">{props.icon}</div>
          )}
          {props.children}
        </>
      )}
    </button>
  );
};

export default SecondaryButton;